import { opaleApiClient } from "./apiClients";
import { apiConfig } from "config/app-config";
import { authenticationService, dateUtil } from "@sdeapps/react-core";
import { AllCourriersDossierDefinitionsMap } from "models";
import type {
  DocumentDataConditionsDto,
  Chantier,
  CourrierType,
  DocumentGenerationAudit,
  Dossier,
} from "models";
import type { AxiosRequestConfig } from "axios";
import { filesService } from "./filesService";
import { Endpoints } from "enums";

interface CourrierGenerationPayload {
  template: string;
  documentType: string;
  dossierId: string;
  chantierId: string;
  saveDirectory: string;
  generatedFileName: string;
}

/**
 * Génère un courrier.
 * @param documentType Type de courrier à générer
 * @param template Nom du fichier de template de courrier à utiliser
 * @param chantier Chantier parent du dossier pour stocker le fichier généré
 * @param dossier Dossier utilisé pour la génération du courrier
 * @returns un objet contenant l'url qui permet d'ouvrir le fichier généré dans sharepoint, ainsi que le nom du fichier généré
 */
async function genererCourrier(
  documentType: CourrierType,
  template: string,
  chantier: Chantier,
  dossier: Dossier
): Promise<{ url: string; generatedFileName: string }> {
  const url = `/${Endpoints.DOCUMENTS_GENERATION}/generate`;

  const payload: CourrierGenerationPayload = {
    template,
    documentType,
    dossierId: dossier.id,
    chantierId: chantier.id,
    saveDirectory: filesService.getCourrierFolderPath(dossier, chantier),
    generatedFileName: `${dossier.reference}_${template.split(".")[0]}_${dateUtil.format(
      new Date(),
      "yyyy-MM-dd-hh-mm-ss"
    )}.${template.split(".")[1]}`,
  };

  const accessToken = await authenticationService.getAccessToken(apiConfig.azureAd.scopes);
  const headers: AxiosRequestConfig<CourrierGenerationPayload> = {
    headers: {
      "x-ms-token-aad-id-token": `Bearer ${accessToken}`,
    },
  };
  const { url: sharePointUrl } = await opaleApiClient.post<
    CourrierGenerationPayload,
    { url: string }
  >(url, payload, headers);
  return {
    url: sharePointUrl,
    generatedFileName: payload.generatedFileName,
  };
}

/**
 * Retourne un objet contenant les conditions utilisées lors de la génèration de courrier d'un dossier de chantier.
 * @param chantierId Identifiant du chantier parent
 * @returns Le DTO contenant toutes les conditions évaluées pour les courriers de ce chantier
 */
async function getDocumentConditions(chantierId: string): Promise<DocumentDataConditionsDto> {
  const url = `/${Endpoints.DOCUMENTS_GENERATION}/chantiers/${chantierId}/conditions`;
  return await opaleApiClient.get<DocumentDataConditionsDto, DocumentDataConditionsDto>(url);
}

/**
 * Retourne une liste de `DocumentGenerationAudit` depuis l'API filtrée sur le chantier passé en paramètre
 * @param chantierId Identifiant du chantier parent
 * @returns Liste de `DocumentGenerationAudit` qui peut être vide
 */
async function getChantierDocumentGeneration(
  chantierId: string
): Promise<Array<DocumentGenerationAudit>> {
  const url = `/${Endpoints.DOCUMENTS_GENERATION}/chantiers/${chantierId}`;
  return await opaleApiClient.get<Array<DocumentGenerationAudit>, Array<DocumentGenerationAudit>>(
    url
  );
}

/**
 * Retourne une liste de `DocumentGenerationAudit` depuis l'API filtrée sur le chantier et le dossier passés
 * en paramètres
 * @param chantierId Identifiant du chantier parent
 * @param dossierId Identifiant du dossier
 * @returns Liste de `DocumentGenerationAudit` qui peut être vide
 */
async function getDossierDocumentGeneration(
  chantierId: string,
  dossierId: string
): Promise<Array<DocumentGenerationAudit>> {
  const url = `/${Endpoints.DOCUMENTS_GENERATION}/chantiers/${chantierId}/dossiers/${dossierId}`;
  return await opaleApiClient.get<Array<DocumentGenerationAudit>, Array<DocumentGenerationAudit>>(
    url
  );
}

/**
 * Retrouve le libellé du bouton du courrier à partir d'un document généré avec la référence du dossier.
 * Lorsque le libellé n'est pas retrouvé alors la valeur passée en argument est retournée en l'état.
 * @param documentName Nom du document Word avec la référence du dossier en préfixe, et l'extension de fichier `.docx`
 * @returns Le libellé utilisé pour le bouton de génération de ce document.
 */
function getDocumentLibelleByDocumentName(documentName: string): string {
  const matches =
    /^(?:\d{1,5}\s\w{1,3}_)?(?<docName>[^.]+?)(?:_\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2})?\.(?<docExt>\w+)$/.exec(
      documentName
    );
  const docName =
    matches?.groups?.docName != null && matches?.groups?.docExt != null
      ? `${matches?.groups?.docName}.${matches?.groups?.docExt}`
      : documentName;
  return AllCourriersDossierDefinitionsMap[docName] ?? documentName;
}

export const courriersService = {
  genererCourrier,
  getDocumentConditions,
  getChantierDocumentGeneration,
  getDossierDocumentGeneration,
  getDocumentLibelleByDocumentName,
};
