import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import type { Commune } from "models";
import { stringUtil } from "@sdeapps/react-core";
import { SkeletonInput } from "components/Loading";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";

type CommuneFiltrable = Commune & {
  libelleNormalise: string;
};
interface CommuneAutoCompleteProps {
  communes: Array<Commune>;
  isLoading?: boolean;
  onChange: (commune?: Commune) => void;
  selectedCommuneId?: string;
  size?: "small" | "medium";
  label?: string;
  error?: Error;
}

function CommuneAutoComplete({
  communes,
  isLoading = false,
  onChange,
  selectedCommuneId,
  size = "medium",
  label = "Commune",
  error,
}: Readonly<CommuneAutoCompleteProps>): ReactNode {
  const [communesFiltrables, setCommunesFiltrables] = useState<Array<CommuneFiltrable>>([]);
  const [selectedCommune, setSelectedCommune] = useState<CommuneFiltrable | undefined>(undefined);

  const { setOriginalError } = useErrorHandler();
  useEffect(() => {
    if (error != null) setOriginalError(error);
  }, [error, setOriginalError]);

  useEffect(() => {
    const communesNormalisees = (communes ?? [])
      .map<CommuneFiltrable>(({ id, libelle }) => ({
        id,
        libelle,
        libelleNormalise: stringUtil.normalize(libelle).toUpperCase(),
      }))
      .sort((a, b) => {
        return a.libelleNormalise.localeCompare(b.libelleNormalise);
      });
    setCommunesFiltrables(communesNormalisees);
  }, [communes]);

  useEffect(() => {
    setSelectedCommune(communesFiltrables.find((com) => com.id === selectedCommuneId));
  }, [communesFiltrables, selectedCommuneId]);

  if (isLoading) {
    return <SkeletonInput height={size === "small" ? 40 : undefined} />;
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        size={size}
        renderInput={(params) => <TextField {...params} label={label} placeholder={label} />}
        value={selectedCommune ?? null}
        onChange={(_, newValue) => {
          const selectionCommune = newValue ?? undefined;
          setSelectedCommune(selectionCommune);

          if (selectionCommune != null) {
            const { libelleNormalise, ...commune } = selectionCommune;
            onChange(commune);
          } else {
            onChange(undefined);
          }
        }}
        options={communesFiltrables}
        renderOption={(props, option: CommuneFiltrable) => (
          <Box component="li" {...props} key={option.id}>
            {option.libelle}
          </Box>
        )}
        getOptionLabel={(option: CommuneFiltrable) => option.libelle}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options, { inputValue }) =>
          options.filter((opt) =>
            opt.libelleNormalise.includes(stringUtil.normalize(inputValue).toUpperCase())
          )
        }
      />
    </FormControl>
  );
}

export const CommuneAutoCompleteWithErrorBoundary = withComponentErrorBoundary(CommuneAutoComplete);
