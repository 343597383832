import type { File, Folder } from "models";

/**
 * Transforme des données stream d'une requête HTTP en fichier téléchargé par le navigaateur.
 * @param data Données à télécharger.
 * @param fileName Nom du fichier qui sera téléchargé.
 * @param contentType Type du fichier qui sera téléchargé.
 */
function downloadStreamAsFile(
  data: BlobPart | Array<BlobPart>,
  fileName: string,
  contentType?: string
): void {
  const blob = new Blob(Array.isArray(data) ? data : [data], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
}

function isFile(fileOrFolder: File | Folder): fileOrFolder is File {
  return !("folder" in fileOrFolder);
}

function isFolder(fileOrFolder: File | Folder): fileOrFolder is Folder {
  return "folder" in fileOrFolder;
}

function getFileExtension(file: File): string | undefined {
  return file.name.split(".").pop();
}

function getFileNameWithoutExtension(file: File): string | undefined {
  return file.name.split(".").slice(0, -1).join(".");
}

export const fileUtil = {
  downloadStreamAsFile,
  isFile,
  isFolder,
  getFileExtension,
  getFileNameWithoutExtension,
};
