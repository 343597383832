import { useEffect, useState, type ReactNode } from "react";
import type { SvgIconProps } from "@mui/material";
import type { File } from "models";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { fileUtil } from "utils/fileUtil";
import { WordFileIcon } from "icons/WordFileIcon";
import { PdfFileIcon } from "icons/PdfFileIcon";
import ImageIcon from "@mui/icons-material/Image";
import { grey } from "@mui/material/colors";

const WORD_COLOR = "#185abd";

interface FileIconProps extends SvgIconProps {
  file: File;
  fontSize: SvgIconProps["fontSize"];
}

function FileIcon({ file, fontSize = "small", ...iconProps }: Readonly<FileIconProps>): ReactNode {
  const [fileExtension, setFileExtension] = useState<string>();

  useEffect(() => {
    setFileExtension(fileUtil.getFileExtension(file));
  }, [file]);

  switch (fileExtension) {
    case "docx":
    case "doc":
      return <WordFileIcon fontSize={fontSize} {...iconProps} sx={{ color: WORD_COLOR }} />;

    case "pdf":
      return <PdfFileIcon />;

    case "png":
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjpeg":
    case "pjp":
    case "gif":
    case "webp":
    case "bmp":
      return <ImageIcon fontSize={fontSize} sx={{ color: grey[600] }} {...iconProps} />;

    default:
      return <InsertDriveFileIcon fontSize={fontSize} sx={{ color: grey[600] }} {...iconProps} />;
  }
}

export default FileIcon;
