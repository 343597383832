import { useCallback } from "react";
import type { ReactNode } from "react";
import { ToastMessages } from "enums";
import type { CourrierMetadata, Chantier, Dossier, CourrierType } from "models";
import { enqueueSnackbar } from "notistack";
import { courriersService, filesService } from "services";
import { useSnackbarErrorHandler, SdeappsErrorNames } from "utils/errorHandling";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { sharepointService } from "services/sharepointService";
import LoadingButton from "components/LoadingButton";

interface DocumentGenerationButtonProps {
  courrierMetaData: CourrierMetadata;
  chantier: Chantier;
  dossier: Dossier;
  beforeGeneration?: () => Promise<void>;
  afterGeneration?: () => void;
}

export function DocumentGenerationButton({
  courrierMetaData: { type, template, libelle },
  chantier,
  dossier,
  beforeGeneration,
  afterGeneration,
}: Readonly<DocumentGenerationButtonProps>): ReactNode {
  const { catchErrors, isLoading } = useSnackbarErrorHandler({
    [SdeappsErrorNames.Locked]: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.LOCKED_COURRIER,
      });
    },
  });

  const sendGenerateDocument = useCallback(
    (typeCourrier: CourrierType, template: string, openFile?: "browser" | "desktop") => {
      return function () {
        async function generateDocument(): Promise<void> {
          if (beforeGeneration != null) {
            await beforeGeneration();
          }
          const { url: sharepointFileUrl, generatedFileName } =
            await courriersService.genererCourrier(typeCourrier, template, chantier, dossier);
          enqueueSnackbar({
            variant: "success",
            message: `Le courrier ${libelle} a été généré avec succès`,
          });
          if (openFile === "browser") {
            sharepointService.openSharepointFileInBrowser(sharepointFileUrl);
          } else if (openFile === "desktop") {
            sharepointService.openSharepointFileInDesktopApp(
              filesService.getCourrierUrl(dossier, chantier, generatedFileName)
            );
          }
          if (afterGeneration != null) {
            afterGeneration();
          }
        }

        void catchErrors(generateDocument);
      };
    },
    [catchErrors, chantier, dossier, libelle, beforeGeneration, afterGeneration]
  );

  return (
    <LoadingButton
      variant="text"
      size="small"
      color="primary"
      onClick={sendGenerateDocument(type, template, "desktop")}
      startIcon={<NoteAddIcon />}
      type="button"
      loading={isLoading}>
      {libelle}
    </LoadingButton>
  );
}
