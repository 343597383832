import { useCallback, useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Collapse, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { graphService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import CachedIcon from "@mui/icons-material/Cached";
import { yellow } from "@mui/material/colors";
import type { Folder, File } from "models";
import SelectableFileItem from "./SelectableFileItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { fileUtil } from "utils/fileUtil";
import { OpenedFolderIcon } from "icons/OpenedFolderIcon";
import { FolderIcon } from "icons/FolderIcon";

function getSexyFolderColor(graphColor: string | undefined): string {
  switch (graphColor) {
    case "lightBlue": // EAU
      return "eau.main";
    case "lightGreen": // ASS
      return "ass.main";
    case "darkRed": // CIPA
      return "cipa.main";
    case "lightOrange": // PAC
      return "pac.main";
    default:
      return graphColor ?? yellow[700];
  }
}

interface FileListPocProps {
  itemId: string;
  defaultIsOpen?: boolean;
}

function FolderList({ itemId, defaultIsOpen = false }: Readonly<FileListPocProps>): ReactElement {
  const [folder, setFolder] = useState<Folder>();
  const [files, setFiles] = useState<Array<File>>([]);
  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const { catchErrors, isLoading } = useErrorHandler();

  const getChildren = useCallback(async (): Promise<void> => {
    const filesAndFolders: Array<File | Folder> = await graphService.getDriveItemChildren(itemId);
    setFiles(filesAndFolders.filter(fileUtil.isFile));
    setFolders(filesAndFolders.filter(fileUtil.isFolder));
  }, [itemId]);

  const getFolder = useCallback(async (): Promise<void> => {
    const folder = await graphService.getDriveItem(itemId);
    if (fileUtil.isFolder(folder)) {
      setFolder(folder);
    } else {
      console.warn("IS NOT FOLDER", folder);
    }
  }, [itemId]);

  const getFolderAndChildren = useCallback((): void => {
    void catchErrors(getFolder);
    void catchErrors(getChildren);
  }, [catchErrors, getChildren, getFolder]);

  useEffect(() => {
    getFolderAndChildren();
  }, [getFolderAndChildren]);

  return (
    <Grid container size={12}>
      <Grid container size={12} alignItems="center">
        <Grid
          container
          sx={{ ":hover": { cursor: "pointer" } }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          alignItems="center">
          <IconButton size="small">{isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
          {isOpen ? (
            <OpenedFolderIcon
              sx={{
                color: getSexyFolderColor(folder?.folder?.decorator?.iconColor),
                mr: 1,
              }}
              fontSize="small"
            />
          ) : (
            <FolderIcon
              sx={{
                color: getSexyFolderColor(folder?.folder?.decorator?.iconColor),
                mr: 1,
              }}
              fontSize="small"
            />
          )}
          <Typography>{folder?.name}</Typography>
        </Grid>
        <Grid container alignItems="center" sx={{ ml: 1 }}>
          <IconButton
            loading={isLoading}
            onClick={getFolderAndChildren}
            color="primary"
            size="small">
            <CachedIcon sx={{ fontSize: ".8em" }} />
          </IconButton>
        </Grid>
      </Grid>
      {(folders.length > 0 || files.length > 0) && (
        <Collapse in={isOpen} timeout={200} sx={{ width: "100%" }}>
          <Grid container size={12} sx={{ pl: 4 }} spacing={0.5}>
            {folders.map((folder) => (
              <Grid container key={folder.id} size={12}>
                <FolderList itemId={folder.id} />
              </Grid>
            ))}

            {files.map((file) => (
              <Grid key={file.id} size={12}>
                <SelectableFileItem file={file} />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      )}
    </Grid>
  );
}

export default FolderList;
