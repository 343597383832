import { useEffect } from "react";
import type { ReactNode } from "react";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DiametreBranchementAss, NatureConduiteAss } from "models";
import type { DossierAss, DossierAssTechniqueModifyDTO, PatchData } from "models";
import { FormSection } from "components/Layout";
import { SdeappsError, useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier } from "providers";
import { FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import {
  ControlledBooleanRadio,
  ControlledTextField,
  ControlledNumberField,
} from "components/Inputs";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { dossierService } from "services";
import patchUtils from "utils/patchUtils";
import AspectFinancierAssForm from "./AspectFinancierAssForm";
import DossierSections from "../DossierSections";
import { dossierAssSections } from "../routes/dossiersSectionsDefinitions";

const propertiesExcludedFromPatch: Array<keyof DossierAssTechniqueModifyDTO> = [
  "branchementParticulier",
  "eauxPluviales",
  "aspectFinancier",
];

function getBranchementsPatchData(dossierData: DossierAssTechniqueModifyDTO): Array<PatchData> {
  if (Object.entries(dossierData.branchementParticulier).some(([_, value]) => value != null)) {
    return [
      {
        op: "replace",
        path: "/branchementParticulier",
        value: dossierData.branchementParticulier,
      },
    ];
  }
  return [];
}

function getEauxPluvialesPatchData(dossierData: DossierAssTechniqueModifyDTO): Array<PatchData> {
  if (Object.entries(dossierData.eauxPluviales).some(([_, value]) => value != null)) {
    return [
      {
        op: "replace",
        path: "/eauxPluviales",
        value: dossierData.eauxPluviales,
      },
    ];
  }
  return [];
}

function getCalculsPatchData(dossierData: DossierAssTechniqueModifyDTO): Array<PatchData> {
  if (dossierData.aspectFinancier?.montantDevis != null) {
    return [
      {
        op: "replace",
        path: "/aspectFinancier",
        value: dossierData.aspectFinancier,
      },
    ];
  }

  return [];
}

function toPatchData(dossierData: DossierAssTechniqueModifyDTO): Array<PatchData> {
  const patches = patchUtils.toPatchData(dossierData, propertiesExcludedFromPatch);

  return [
    ...patches,
    ...getBranchementsPatchData(dossierData),
    ...getEauxPluvialesPatchData(dossierData),
    ...getCalculsPatchData(dossierData),
  ];
}

function fromDossierToDossierAssTechniqueModifyDTO(
  dossier: DossierAss
): DossierAssTechniqueModifyDTO {
  const dossierAssTechnique: DossierAssTechniqueModifyDTO = {
    servitudeDePose: dossier?.servitudeDePose ?? null,
    descriptionTravaux: dossier?.descriptionTravaux,
    branchementParticulier: {
      natureConduite: dossier?.branchementParticulier?.natureConduite,
      longueur: dossier?.branchementParticulier?.longueur,
      diametre: dossier?.branchementParticulier?.diametre,
      nombreDeRegards: dossier?.branchementParticulier?.nombreDeRegards,
    },
    eauxPluviales: {
      natureConduite: dossier?.eauxPluviales?.natureConduite,
      longueur: dossier?.eauxPluviales?.longueur,
      diametre: dossier?.eauxPluviales?.diametre,
      nombreDeRegards: dossier?.eauxPluviales?.nombreDeRegards,
    },
    aspectFinancier: dossier?.aspectFinancier,
  };

  return dossierAssTechnique;
}

function DossierAssFormTechnique(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierAss>();

  const formMethods = useForm<DossierAssTechniqueModifyDTO>({
    shouldFocusError: false,
    defaultValues: { branchementParticulier: {} },
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const { catchErrors } = useErrorHandler({
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  useEffect(() => {
    if (dossier != null) {
      reset(fromDossierToDossierAssTechniqueModifyDTO(dossier));
    }
  }, [dossier, reset]);

  const sendData: SubmitHandler<DossierAssTechniqueModifyDTO> = async function (
    dossierData: DossierAssTechniqueModifyDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }

    await catchErrors(async () => {
      const patchData = toPatchData(dossierData);
      await dossierService.patchDossier(dossier.idChantier, dossier.id, patchData);
      updateDossier();
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <DossierSections isLoading={false} sections={dossierAssSections} />

        {dossier == null || isDossierLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
            <FormSection title="Caractéristiques">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledBooleanRadio
                  name="servitudeDePose"
                  label="Servitude de pose"
                  defaultValue={null}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 9 }}>
                <ControlledTextField
                  name="descriptionTravaux"
                  label="Description des travaux"
                  multiline
                />
              </Grid>
            </FormSection>

            <FormSection title="Eaux usées / Unitaire">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField
                  name="branchementParticulier.natureConduite"
                  label="Nature de la conduite"
                  select>
                  {Object.entries(NatureConduiteAss).map(([key, natureConduite]) => (
                    <MenuItem key={key} value={natureConduite}>
                      {natureConduite}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  name="branchementParticulier.longueur"
                  label="Longueur en mètres"
                  type="entier"
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField
                  name="branchementParticulier.diametre"
                  label="Diamètre en mm"
                  select>
                  {Object.entries(DiametreBranchementAss).map(([key, diametre]) => (
                    <MenuItem key={key} value={diametre}>
                      {key.substring(1)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  name="branchementParticulier.nombreDeRegards"
                  label="Nombre de regards"
                  type="entier"
                />
              </Grid>
            </FormSection>

            <FormSection title="Eaux pluviales">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField
                  name="eauxPluviales.natureConduite"
                  label="Nature de la conduite"
                  select>
                  {Object.entries(NatureConduiteAss).map(([key, natureConduite]) => (
                    <MenuItem key={key} value={natureConduite}>
                      {natureConduite}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  name="eauxPluviales.longueur"
                  label="Longueur en mètres"
                  type="entier"
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField name="eauxPluviales.diametre" label="Diamètre en mm" select>
                  {Object.entries(DiametreBranchementAss).map(([key, diametre]) => (
                    <MenuItem key={key} value={diametre}>
                      {key.substring(1)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  name="eauxPluviales.nombreDeRegards"
                  label="Nombre de regards"
                  type="entier"
                />
              </Grid>
            </FormSection>
            <FormSection title="Aspect Financier" size={{ xs: 12, md: 9, lg: 6 }}>
              <AspectFinancierAssForm />
            </FormSection>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export const DossierAssFormTechniqueWithErrorBoundary =
  withPageErrorBoundary(DossierAssFormTechnique);
