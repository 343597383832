import { createSvgIcon } from "@mui/material";

export const WordFileIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    fill="currentColor">
    <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 20 7 L 20 9 L 14 9 L 14 11 L 20 11 L 20 13 L 14 13 L 14 15 L 20 15 L 20 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 3.2949219 8.2949219 L 4.8300781 8.2949219 L 5.3222656 11.773438 C 5.3572656 11.982437 5.3845781 12.267141 5.3925781 12.619141 L 5.4179688 12.619141 C 5.4259688 12.362141 5.4624375 12.068906 5.5234375 11.753906 L 6.1542969 8.2949219 L 8.0234375 8.2949219 L 8.6074219 11.742188 C 8.6424219 11.923187 8.6673594 12.189203 8.6933594 12.533203 L 8.7128906 12.533203 C 8.7208906 12.266203 8.74725 11.989125 8.78125 11.703125 L 9.2597656 8.2949219 L 10.703125 8.2949219 L 9.3203125 15.705078 L 8.0058594 15.705078 L 7.1269531 11.744141 C 7.0829531 11.497141 7.0588281 11.219062 7.0488281 10.914062 L 7.03125 10.914062 C 7.01325 11.247063 6.9785469 11.524141 6.9355469 11.744141 L 6.0390625 15.705078 L 4.671875 15.705078 L 3.2949219 8.2949219 z" />
  </svg>,
  "WordFileIcon"
);
