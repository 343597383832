import { useEffect, useState, type ReactElement } from "react";
import { Checkbox, FormControlLabel, List, ListItem, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoadingButton from "components/LoadingButton";
import { useMail } from "../providers/MailProvider";
import SelectableFileItem from "./SelectableFileItem";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import { emailService } from "services";
import { useChantier } from "providers";
import type { File } from "models";
import FileItem from "./FileItem";
import { fileUtil } from "utils/fileUtil";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";

function isWordFileFilter(file: File): boolean {
  return ["doc", "docx"].includes(fileUtil.getFileExtension(file) ?? "");
}

function SelectedFilesList(): ReactElement {
  const [convertToPdf, setConvertToPdf] = useState(true);
  const { selectedFiles } = useMail();
  const [fileItemIdsAsOriginal, setFileItemIdsAsOriginal] = useState<Array<string>>([]);
  const [fileItemIdsAsPdf, setFileItemIdsAsPdf] = useState<Array<string>>([]);

  useEffect(() => {
    if (convertToPdf) {
      setFileItemIdsAsOriginal(selectedFiles.filter((f) => !isWordFileFilter(f)).map((f) => f.id));
      setFileItemIdsAsPdf(selectedFiles.filter(isWordFileFilter).map((f) => f.id));
    } else {
      setFileItemIdsAsOriginal(selectedFiles.map((f) => f.id));
      setFileItemIdsAsPdf([]);
    }
  }, [selectedFiles, convertToPdf]);

  const { chantier } = useChantier();

  const { catchErrors, isLoading } = useSnackbarErrorHandler();

  function toogleConvertToPdf(): void {
    setConvertToPdf(!convertToPdf);
  }

  function generateAndDownloadEmail(): void {
    if (chantier != null) {
      const payload = {
        attachmentsItemIds: {
          asPdf: fileItemIdsAsPdf,
          asOriginal: fileItemIdsAsOriginal,
        },
      };

      void catchErrors(async () => {
        const emailString = await emailService.generateEmail(chantier.id, payload);
        fileUtil.downloadStreamAsFile(emailString, "email.eml", "message/rfc822");
      });
    }
  }

  return (
    <Grid container padding={2} size={12}>
      <Grid container alignItems="center" spacing={2} size={12}>
        <Grid container size="auto">
          <Typography variant="h5" sx={{ ml: 1 }}>
            Fichiers sélectionnés
          </Typography>
        </Grid>
      </Grid>
      <Grid size={12} flexGrow={1}>
        <List dense>
          {selectedFiles.map((file: File) =>
            fileItemIdsAsPdf.includes(file.id) ? (
              <ListItem key={file.id}>
                <Grid container key={file.id} sx={{ width: "100%" }} spacing={1}>
                  <Grid size={12}>
                    <SelectableFileItem key={file.id} file={file} />
                  </Grid>
                  <Grid size={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ opacity: 0.6, pl: 4, width: "100%" }}>
                      <TurnLeftIcon fontSize="small" sx={{ transform: "rotate(180deg)" }} />
                      <FileItem
                        file={{
                          ...file,
                          name: `${fileUtil.getFileNameWithoutExtension(file)}.pdf`,
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            ) : (
              <ListItem key={file.id}>
                <SelectableFileItem key={file.id} file={file} />
              </ListItem>
            )
          )}
        </List>
      </Grid>
      <Grid container spacing={1} size={12}>
        <Grid size={12}>
          <FormControlLabel
            control={<Checkbox checked={convertToPdf} onClick={toogleConvertToPdf} />}
            label="Convertir les words en pdfs"
          />
        </Grid>
        <Grid size={12}>
          <LoadingButton
            sx={{ width: "100%" }}
            loading={isLoading}
            onClick={generateAndDownloadEmail}>
            Envoyer ces fichiers par mail
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectedFilesList;
