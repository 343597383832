import type { Perimetre } from "models";
import { Endpoints } from "enums";
import { collectivitesApiClient } from "./apiClients";

/**
 * Retourne les détails d'un périmètre.
 * @param perimetreId L'identifiant du périmètre
 */
async function getById(perimetreId: string): Promise<Perimetre> {
  const url = `/${Endpoints.PERIMETRES}/${perimetreId}`;
  return collectivitesApiClient.get<Perimetre, Perimetre>(url);
}

export const perimetreService = {
  getById,
};
