import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  SdeappsError,
  useErrorHandler,
  useSnackbarErrorHandler,
  withPageErrorBoundary,
} from "utils/errorHandling";
import { useDossierPerimetre, useRequiredParams } from "hooks";
import {
  Grid2 as Grid,
  Radio,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingButton from "components/LoadingButton";
import { TypeDossier } from "models";
import type { Avis, DossierCreateDTO, PaginatedResult, Perimetre } from "models";
import { avisService, dossierService } from "services";
import { useChantier } from "providers";
import { grey } from "@mui/material/colors";
import { FormSection } from "components/Layout";
import DossierChip from "pages/Recherche/components/DossierChip";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "config/app-config";
import { dateUtil } from "@sdeapps/react-core";
import { enqueueSnackbar } from "notistack";
import { getDossiers } from "utils/dossierUtil";
import { ControlledObjectSelect, ControlledRadioGroup } from "components/Inputs";
import { FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { ToastMessages } from "enums";
import { SkeletonInput } from "components/Loading";

const dateFormat = "yyyy-MM-dd";

function NewDossierPage(): ReactNode {
  const navigate = useNavigate();
  // On sait des conditions de ChantierDetails que typeDossier est un TypeDossier valide
  const { idChantier, typeDossier } = useRequiredParams<{
    idChantier: string;
    typeDossier: TypeDossier;
  }>();
  const [results, setResults] = useState<PaginatedResult<Avis>>();
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>();
  const [isPerimetreSelectReadOnly, setIsPerimetreSelectReadOnly] = useState(false);
  const { getPerimetresByCommune } = useDossierPerimetre();
  const { chantier, updateChantier } = useChantier();

  const formMethods = useForm<DossierCreateDTO>({
    shouldFocusError: false,
    defaultValues: { type: typeDossier },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = formMethods;

  const { catchErrors, isLoading } = useSnackbarErrorHandler();
  const { catchErrors: catchAvisErrors, isLoading: isAvisLoading } = useErrorHandler({
    defaultIsLoading: false,
  });
  const { catchErrors: catchPerimetresErrors, isLoading: isPerimetresLoading } = useErrorHandler({
    defaultIsLoading: false,
  });

  useEffect(() => {
    // failsafe : si il y a déjà un dossier existant de ce type dans le chantier, on y redirige l'utilisateur
    if (chantier != null) {
      const alreadyExistsDossier = getDossiers(chantier).find((d) => d.type === typeDossier);
      if (alreadyExistsDossier != null) {
        navigate(
          routesConfig.chantierDossier.getParameterPath(chantier.id, alreadyExistsDossier.id)
        );
      }
    }
  }, [typeDossier, chantier, navigate]);

  useEffect(() => {
    async function getAvisRecommandations(): Promise<void> {
      setResults(undefined);
      if (typeDossier !== TypeDossier.PAC) {
        const _results = await avisService.getAllAvis({
          codeInseeCommune: chantier?.adresse.codeInsee,
          demandeur: chantier?.demandeur1.codeSap,
          type: typeDossier,
          onlyWithDossier: false,
          offset: 0,
          size: 1000,
        });
        // on range les plus récents en premier
        _results.result.sort((r1, r2) =>
          dateUtil.compareDesc(
            dateUtil.fromFormatToDate(r1.dateDemande, dateFormat),
            dateUtil.fromFormatToDate(r2.dateDemande, dateFormat)
          )
        );

        setResults(_results);
      }
    }

    async function getPerimetres(): Promise<void> {
      setPerimetres(undefined);
      const { perimetresEau, perimetresAss } = await getPerimetresByCommune(
        chantier?.adresse.codeInsee
      );
      const _perimetres = typeDossier === TypeDossier.EAU ? perimetresEau : perimetresAss;

      // On définit le périmètre sélectionné par défaut
      const defaultPerimetre =
        typeDossier === TypeDossier.ASS ||
        typeDossier === TypeDossier.CIPA ||
        typeDossier === TypeDossier.PAC
          ? chantier?.dossierAss?.perimetre ??
            chantier?.dossierCipa?.perimetre ??
            chantier?.dossierPac?.perimetre
          : undefined;

      setIsPerimetreSelectReadOnly(defaultPerimetre != null || _perimetres?.length === 1);
      setValue("perimetre", _perimetres?.length === 1 ? _perimetres[0] : defaultPerimetre);

      setPerimetres(_perimetres);
    }

    reset({ type: typeDossier });
    void catchAvisErrors(getAvisRecommandations);
    void catchPerimetresErrors(getPerimetres);
  }, [
    catchAvisErrors,
    catchPerimetresErrors,
    chantier,
    getPerimetresByCommune,
    reset,
    setValue,
    typeDossier,
  ]);

  const isNotValid =
    perimetres == null ||
    perimetres.length === 0 ||
    results?.result.length === 0 ||
    isAvisLoading ||
    isPerimetresLoading;

  const sendData: SubmitHandler<DossierCreateDTO> = async function (
    dossier: DossierCreateDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("LE DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid || isNotValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }

    await catchErrors(async () => {
      const newDossierId = await dossierService.createDossier(idChantier, dossier);
      await updateChantier();
      navigate(routesConfig.chantierDossier.getParameterPath(idChantier, newDossierId));
    });
  };

  return (
    <Grid container size={12}>
      <FormProvider {...formMethods}>
        <Grid
          component="form"
          container
          spacing={2}
          sx={{ p: 2, background: grey[200] }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(sendData, () => {
            enqueueSnackbar({
              variant: "error",
              message: ToastMessages.ERROR_FORM_VALIDATION,
            });
          })}
          size={12}>
          <FormSection>
            <Typography component={Grid} size={12} color="primary" variant="body1" fontWeight={600}>
              Sélectionnez un périmètre {typeDossier === TypeDossier.EAU ? "EAU" : "ASS"}
            </Typography>
            {isPerimetresLoading && <SkeletonInput sx={{ width: "100%" }} />}
            {!isPerimetresLoading &&
              (perimetres == null || perimetres.length === 0 ? (
                <Typography>
                  Le SDEA n'est pas compétent en {typeDossier === TypeDossier.EAU ? "EAU" : "ASS"}{" "}
                  pour la commune sélectionnée
                </Typography>
              ) : (
                <ControlledObjectSelect
                  name="perimetre"
                  label="Périmètre"
                  readOnly={isPerimetreSelectReadOnly}
                  disabled={isPerimetreSelectReadOnly}
                  menuItems={perimetres}
                  menuItemKeyName="id"
                  menuItemLibelleName="libelle"
                  rules={{
                    validate: (newValue) =>
                      newValue != null || "La sélection d'un périmètre est obligatoire.",
                  }}
                />
              ))}
            {typeDossier !== TypeDossier.PAC && (
              <>
                <Typography
                  component={Grid}
                  size={12}
                  color="primary"
                  variant="body1"
                  fontWeight={600}>
                  Sélectionnez un avis {typeDossier}
                </Typography>
                <Grid size={{ xs: 12 }}>
                  <ControlledRadioGroup
                    name="idAvis"
                    rules={{
                      validate: (newValue) =>
                        newValue != null || "La sélection d'un avis est obligatoire.",
                    }}
                    defaultValue={null}>
                    <TableContainer>
                      <Table>
                        <TableHead
                          sx={{ ".MuiTableCell-head .MuiTypography-root": { fontWeight: 600 } }}>
                          <TableRow>
                            <TableCell size="small" padding="checkbox"></TableCell>
                            <TableCell>
                              <Typography>Id</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>Date</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>Commune</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>Demandeur(s)</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isAvisLoading && (
                            <TableRow>
                              <TableCell size="small" padding="checkbox">
                                <Skeleton />
                              </TableCell>
                              {[0, 1, 2, 3, 4].map((i) => (
                                <TableCell key={i}>
                                  <Skeleton />
                                </TableCell>
                              ))}
                            </TableRow>
                          )}
                          {results?.result?.map((avis) => (
                            <TableRow key={avis.idAvis}>
                              <TableCell size="small" padding="checkbox">
                                <Radio value={avis.idAvis} />
                              </TableCell>
                              <TableCell>
                                <Typography>{avis.idAvis}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {dateUtil.format(
                                    dateUtil.fromFormatToDate(avis.dateDemande, dateFormat),
                                    "dd/MM/yyyy"
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  <DossierChip sx={{ marginY: -1 }} type={avis.typeDemande} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{avis.nomCommune}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {avis.demandeur1.nom} {avis.demandeur1.prenom}
                                  {avis.demandeur2 != null &&
                                    ` / ${avis.demandeur2.nom} ${avis.demandeur2.prenom}`}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                          {results?.result.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <Typography sx={{ textAlign: "center" }}>
                                  Aucun avis {typeDossier} ne correspond
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ControlledRadioGroup>
                </Grid>
              </>
            )}
            <Grid size={{ xs: 12 }}>
              <LoadingButton type="submit" loading={isLoading} disabled={isNotValid}>
                Ajouter un Dossier {typeDossier}
              </LoadingButton>
            </Grid>
          </FormSection>
        </Grid>
      </FormProvider>
    </Grid>
  );
}

export const NewDossierWithErrorBoundary = withPageErrorBoundary(NewDossierPage);
