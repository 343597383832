import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**  Payload pour la génération d'un email. */
type GenerateEmailDto = {
  /** Les identifiants Sharepoint (item-id) des fichier à joindre à l'émail. */
  attachmentsItemIds: {
    /** Les item-id des fichiers Word à joindre au format PDF après conversion. */
    asPdf?: Array<string>;
    /** Les item-id des autres fichiers à joindre sans conversion. */
    asOriginal?: Array<string>;
  };
};

/**
 * Génère un email au formal EML pour un chantier.
 * @param chantierId L'identifiant du chantier
 * @param payload Les identifiants des pièces jointes à inclure
 * @returns Le contenu de l'email généré au format texte.
 */
async function generateEmail(chantierId: string, payload: GenerateEmailDto): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/generateEmail`;
  return opaleApiClient.post(url, payload);
}

export const emailService = {
  generateEmail,
};
