import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import NavigationMenu from "./Header/NavigationMenu";
import { Box, Container } from "@mui/material";
import { useDocumentTitle, usePersistentNavigationMenu } from "hooks";

export function Root(): ReactNode {
  useDocumentTitle();
  const { isNavMenuOpen, setIsNavMenuOpen } = usePersistentNavigationMenu();

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <NavigationMenu isOpen={isNavMenuOpen} onOpen={setIsNavMenuOpen} />
      <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
