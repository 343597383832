import { useEffect } from "react";
import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TypeDossier } from "models";
import type { DossierCipa, DossierCipaAdministratifModifyDTO } from "models";
import {
  ControlledDateTime,
  ControlledStatutDossier,
  ControlledBooleanRadio,
  TechnicienAutoCompleteWithErrorBoundary,
} from "components/Inputs";
import { FormSection } from "components/Layout";
import { dateUtil } from "@sdeapps/react-core";
import { Controller, FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { dossierService } from "services";
import { SdeappsError, useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier } from "providers";
import patchUtils from "utils/patchUtils";
import DossierSections from "../DossierSections";
import { dossierCipaSections } from "../routes/dossiersSectionsDefinitions";

const dateFormat = "yyyy-MM-dd";

function formatDate(date: string | undefined): Date | undefined {
  if (date == null) return undefined;
  return dateUtil.fromFormatToDate(date, dateFormat);
}

function fromDossierToDossierCipaAdministratifModifyDTO(
  dossier: DossierCipa
): DossierCipaAdministratifModifyDTO {
  const dossierCipaAdmin: DossierCipaAdministratifModifyDTO = {
    idTechnicien: dossier.technicien.id,
    nameTechnicien: dossier.technicien.displayName,
    statutDemande: dossier.statutDemande,
    dateDemande: dateUtil.fromFormatToDate(dossier.dateDemande, dateFormat),
    dateValidation: formatDate(dossier.dateValidation),
    recuperationEauxPluviales: dossier.recuperationEauxPluviales ?? false,
  };

  return dossierCipaAdmin;
}

const validDateRule = {
  validate: (value: Date | string | undefined) =>
    dateUtil.isValid(value) || "Veuillez saisir une date valide",
};

function DossierCipaFormAdministratif(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierCipa>();

  const formMethods = useForm<DossierCipaAdministratifModifyDTO>({
    shouldFocusError: false,
  });
  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const { catchErrors, isLoading } = useErrorHandler({
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  useEffect(() => {
    if (dossier != null) {
      reset(fromDossierToDossierCipaAdministratifModifyDTO(dossier));
    }
  }, [dossier, reset]);

  const sendData: SubmitHandler<DossierCipaAdministratifModifyDTO> = async function (
    dossierData: DossierCipaAdministratifModifyDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }
    await catchErrors(async () => {
      const patchData = patchUtils.toPatchData(dossierData);
      await dossierService.patchDossier(dossier.idChantier, dossier.id, patchData);
      updateDossier();
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <DossierSections isLoading={isLoading} sections={dossierCipaSections} />

        {dossier == null || isLoading || isDossierLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
            <FormSection title="Gestion de la demande">
              <Grid size={{ xs: 12, md: 6 }}>
                <Controller
                  name="idTechnicien"
                  defaultValue={dossier.technicien.id}
                  rules={{ required: "Il est obligatoire de saisir un technicien." }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TechnicienAutoCompleteWithErrorBoundary
                      libelle="Suivi par *"
                      technicienId={value}
                      error={error}
                      onChange={(tech) => {
                        onChange(tech?.id);
                        setValue("nameTechnicien", tech?.displayName);
                        setValue("mailTechnicien", tech?.mail);
                        setValue("centreTechnicien", tech?.centre);
                        setValue("telephoneTechnicien", tech?.telephone);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledStatutDossier
                  name="statutDemande"
                  label="Statut *"
                  type={TypeDossier.CIPA}
                />
              </Grid>
            </FormSection>

            <FormSection title="Evolution du dossier">
              <Grid size={12}>
                <Typography variant="body1" fontWeight={600}>
                  Contrôle
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledDateTime
                  label="Date de la demande *"
                  name="dateDemande"
                  rules={{ required: "Ce champ est obligatoire", ...validDateRule }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledDateTime
                  name="dateValidation"
                  label="Date de validation"
                  rules={validDateRule}
                />
              </Grid>
            </FormSection>

            <FormSection title="Caractéristiques">
              <Grid size={12}>
                <ControlledBooleanRadio
                  name="recuperationEauxPluviales"
                  label="Récupération des eaux pluviales"
                  defaultValue={false}
                />
              </Grid>
            </FormSection>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export const DossierCipaFormAdministratifWithErrorBoundary = withPageErrorBoundary(
  DossierCipaFormAdministratif
);
