import type { ReactNode } from "react";
import { useMemo } from "react";
import { Chip, Grid2 as Grid } from "@mui/material";
import type { Grid2Props } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import type { DocumentDataConditionsDto } from "models";

interface DocumentConditionChipProps {
  /** Libellé de la condition, il sera toujours affiché. */
  conditionLabel: string;
  /**
   * Indique si la condition est vérifiée.
   *
   * Cette valeur est utilisée pour conditionnée par exemple :
   * - la couleur
   * - l'icone
   * - l'affichage du complément
   */
  conditionVerified?: boolean;
  /** Information complémentaire à afficher lorsque la condition est vérifiée. */
  complement?: string;
}
function DocumentConditionChip({
  conditionLabel,
  conditionVerified = false,
  complement,
}: Readonly<DocumentConditionChipProps>): ReactNode {
  const label = useMemo(() => {
    if (complement == null || complement === "" || !conditionVerified) {
      return conditionLabel;
    }
    return `${conditionLabel} : ${complement}`;
  }, [conditionLabel, conditionVerified, complement]);

  return (
    <Grid>
      <Chip
        size="small"
        label={label}
        color={conditionVerified ? "success" : "info"}
        variant={conditionVerified ? "filled" : "outlined"}
        icon={conditionVerified ? <CheckIcon /> : <ClearIcon />}
      />
    </Grid>
  );
}

interface DocumentCourriersConditionsProps extends Grid2Props {
  conditions?: DocumentDataConditionsDto;
}
export function DocumentCourriersConditions({
  conditions,
  ...gridProps
}: Readonly<DocumentCourriersConditionsProps>): ReactNode {
  if (conditions == null) {
    return undefined;
  }

  return (
    <Grid size={12} container spacing={1} {...gridProps}>
      <DocumentConditionChip
        conditionLabel="Dossier EAU"
        conditionVerified={conditions.dossierEau}
      />
      <DocumentConditionChip
        conditionLabel="Dossier EAU seul"
        conditionVerified={conditions.dossierEauSansAss}
      />
      <DocumentConditionChip
        conditionLabel="Dossier ASS"
        conditionVerified={conditions.dossierAss}
      />
      <DocumentConditionChip
        conditionLabel="Dossier ASS SEPARATIF SEUL"
        conditionVerified={conditions.branchementAssSeparatifSansEau}
      />
      <DocumentConditionChip
        conditionLabel="Route départementale"
        conditionVerified={conditions.routeDepartementale}
      />
      <DocumentConditionChip
        conditionLabel="PAC"
        conditionVerified={conditions.pac}
        complement={conditions.pacMontant + " €"}
      />
      <DocumentConditionChip
        conditionLabel="Frais d'accès"
        conditionVerified={conditions.fraisAccesEau}
        complement={conditions.fraisAccesEauMontant + " €"}
      />
      <DocumentConditionChip conditionLabel="EMS EAU" conditionVerified={conditions.emsEau} />
      <DocumentConditionChip conditionLabel="EMS ASS" conditionVerified={conditions.emsAss} />
      <DocumentConditionChip
        conditionLabel="Molsheim EAU"
        conditionVerified={conditions.molsheimEau}
      />
      <DocumentConditionChip
        conditionLabel="Molsheim ASS"
        conditionVerified={conditions.molsheimAss}
      />
      <DocumentConditionChip
        conditionLabel="Type d'intervention ASS"
        conditionVerified={true}
        complement={conditions.typeInterventionAss}
      />
    </Grid>
  );
}
