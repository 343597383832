import { useEffect, useState } from "react";
import type { Chantier, DossierAss, DossierCipa, DossierEau } from "models";
import { dossierService } from "services";
import { useDossier } from "providers";

interface IUseDestinataireCopieResult {
  destinataireCopie?: string;
  onDestinataireCopieChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  patchDestinataireCopie: () => Promise<void>;
}

export function useDestinataireCopie(chantier?: Chantier): IUseDestinataireCopieResult {
  const { updateDossier, dossier } = useDossier<DossierEau | DossierAss | DossierCipa>();
  const [destinataireCopie, setDestinataireCopie] = useState<string | undefined>(
    dossier?.destinataireCopie
  );
  useEffect(() => {
    setDestinataireCopie(dossier?.destinataireCopie);
  }, [dossier]);

  function onDestinataireCopieChange({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void {
    setDestinataireCopie(value == null || value?.length === 0 ? undefined : value);
  }

  async function patchDestinataireCopie(): Promise<void> {
    if (chantier != null && dossier != null) {
      if (destinataireCopie !== dossier?.destinataireCopie) {
        const patchData = [
          {
            op: destinataireCopie == null ? "remove" : "replace",
            path: "/destinataireCopie",
            value: destinataireCopie,
          },
        ];
        await dossierService.patchDossier(chantier?.id, dossier?.id, patchData);
        updateDossier();
      }
    } else {
      throw new Error("Chantier ou Dossier n'est pas défini");
    }
  }

  return {
    destinataireCopie,
    onDestinataireCopieChange,
    patchDestinataireCopie,
  };
}
