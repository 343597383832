import type { DossierBase, TypeDossier } from "./Dossier";

export enum StatutDossierCipa {
  COMPLET = "Complet",
  INCOMPLET = "Incomplet",
  PROBLEME_TECHNIQUE = "Problème technique",
  RELANCE_CLOTURE = "Relancé clôturé",
  ANNULE = "Annulé",
}

export interface DossierCipa extends DossierBase {
  statutDemande: StatutDossierCipa;
  type: TypeDossier.CIPA;
  dateDemande: string;

  // #region Données administratives
  dateValidation?: string;
  recuperationEauxPluviales?: boolean;
  // #endregion

  destinataireCopie?: string;

  controles: Array<Controle>;
}

export interface DossierCipaAdministratifModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierCipa;
  dateDemande: Date;

  dateValidation?: Date;
  recuperationEauxPluviales: boolean;
}

export interface ControleCreateDto {
  dateControle: Date | null | string;
  accord: boolean | null;
  observations: string;
}

export interface Controle extends ControleCreateDto {
  id: string;
  accord: boolean;
}
