import { useEffect, useMemo, useState } from "react";
import type { Key, ReactNode } from "react";
import { Tab, Tabs, Typography, tabClasses, tabsClasses } from "@mui/material";
import { TypeDossier } from "models";
import type { Dossier } from "models";
import { useCurrentPathRoute, useRequiredParams } from "hooks";
import { getDossiers } from "utils/dossierUtil";
import { useChantier } from "providers/ChantierProvider";
import { routesConfig } from "config/app-config";
import DossierTabLabel from "./DossierTabLabel";
import { TypeDossierIcon } from "components/TypeDossierIcon";
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const tabStyle = {
  border: "2px solid",
  borderColor: "primary.main",
  borderBottomWidth: 0,
  minHeight: "unset",
  opacity: 1,
  backgroundColor: "#FFFFFF",
  transition: "background-color 0.25s, color 0.25s",
  color: "text.secondary",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "primary.light",
    color: "primary.main",
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: "primary.main",
    color: "#FFFFFF",
  },
  "&:not(:last-child)": {
    borderRightWidth: 0,
  },
  [`&.${tabClasses.selected} + *`]: {
    borderLeftWidth: 0,
  },
};

const allTypeDossiers = Object.values(TypeDossier);

export type TabItemDefinition = {
  key: Key;
  icon?: ReactNode;
  libelle: string;
  disabled?: boolean;
  action: VoidFunction;
};

// inspiration: https://mui-treasury.com/?path=/story/tabs-plain--plain
export function ChantierTabs(): ReactNode {
  const { idChantier, idDossier, typeDossier } = useRequiredParams<{
    idChantier: string;
    idDossier: string | undefined;
    typeDossier: string | undefined;
  }>();

  const { chantier } = useChantier();
  const [tabIndex, setTabIndex] = useState<number | false>(false);
  const [dossiers, setDossiers] = useState<Array<Dossier>>([]);

  const currentPath = useCurrentPathRoute();

  // C'est le type de Dossier de l'url (via le dossier idDossier ou le type typeDossier)
  // qui détermine l'onglet sélectionné
  useEffect(() => {
    if (currentPath === routesConfig.chantierMails.path) {
      setTabIndex(4);
    } else {
      let selectedType: TypeDossier | undefined;
      if (idDossier != null) {
        selectedType = dossiers.find((dossier) => dossier.id === idDossier)?.type;
      }
      if (typeDossier != null) {
        selectedType = typeDossier as TypeDossier;
      }

      const _tabIndex = allTypeDossiers.findIndex((t) => t === selectedType);

      setTabIndex(_tabIndex < 0 ? 0 : _tabIndex);
    }
  }, [currentPath, dossiers, idDossier, typeDossier]);

  useEffect(() => {
    if (chantier != null) {
      setDossiers(getDossiers(chantier));
    }
  }, [chantier]);

  const tabs = useMemo(() => {
    return [
      ...allTypeDossiers.map((type) => {
        const dossier: Dossier | undefined = dossiers.find((d) => d.type === type);
        const lowerCaseType = type.toLowerCase();

        return (
          <Tab
            key={type}
            sx={{
              ...tabStyle,
              borderColor: dossier == null ? grey[400] : `${lowerCaseType}.main`,
              color: dossier == null ? grey[400] : `${lowerCaseType}.dark`,
              "&:hover": {
                backgroundColor: `${lowerCaseType}.light`,
              },
              [`&.${tabClasses.selected}`]: {
                backgroundColor: `${lowerCaseType}.main`,
                borderColor: `${lowerCaseType}.main`,
                color: "#FFFFFF",
              },
            }}
            component={Link}
            to={
              dossier == null
                ? routesConfig.chantierDossierNew.getParameterPath(idChantier, type)
                : routesConfig.chantierDossier.getParameterPath(idChantier, dossier.id)
            }
            disableRipple
            label={
              <DossierTabLabel
                title={dossier == null ? "Ajouter" : dossier.reference}
                typeDossier={type}
              />
            }
            iconPosition="start"
            icon={<TypeDossierIcon type={type} />}
          />
        );
      }),
      <Tab
        key="Mails"
        sx={{
          ...tabStyle,
          borderColor: grey[500],
          color: grey[800],
          "&:hover": {
            backgroundColor: grey[200],
          },
          [`&.${tabClasses.selected}`]: {
            backgroundColor: grey[500],
            color: "#FFFFFF",
          },
        }}
        component={Link}
        to={routesConfig.chantierMails.getParameterPath(idChantier)}
        disableRipple
        label={
          <Typography color="inherit" fontSize="1.2rem" fontWeight="600" lineHeight="20px">
            Mails
          </Typography>
        }
        iconPosition="start"
        icon={<MailOutlineIcon />}
      />,
    ];
  }, [dossiers, idChantier]);

  if (chantier == null) {
    return undefined;
  }

  return (
    <Tabs
      textColor="inherit"
      sx={{
        [`& .${tabsClasses.indicator}`]: { display: "none" },
        "&.MuiTabs-root": { minHeight: "unset" },
      }}
      value={tabIndex}>
      {tabs}
    </Tabs>
  );
}
