import { TypeDossier } from "./Dossier";

export type CourrierType =
  | "AcceptationTravauxAss"
  | "AcceptationTravauxEau"
  | "Achevement"
  | "AccuseReceptionDossierCipaComplet"
  | "AccuseReceptionDossierCipaIncomplet"
  | "AutorisationRaccordement"
  | "AutorisationDeversement"
  | "Branchement"
  | "InstallationCompteurSupplementaire"
  | "ManquePiecesDossierEau"
  | "ManquePiecesDossierAss"
  | "ModificationBranchementEau";

export type CourrierMetadata = {
  /**
   * Type de courrier qui correspond aux valeurs de l'enum de l'API Opale.
   * Seuls les courriers implémentés sont déclarés.
   */
  type: CourrierType;
  dossierType: TypeDossier;
  libelle: string;
  template: string;
};

/**
 * Liste globale des courriers traités par l'application.
 * Sa définition centralisée permet par exemple:
 * - d'en extraire une sous-liste par type de dossier
 * - de construire un dictionnaire renversé pour retrouver un élément à partir d'une clé telle que le nom du template
 */
const AllCourriersDossierDefinitions: Array<CourrierMetadata> = [
  // EAU
  {
    type: "AcceptationTravauxEau",
    dossierType: TypeDossier.EAU,
    libelle: "Acceptation de travaux",
    template: "EXE_R011_Formulaire_AcceptationTravauxEAU.docx",
  },
  {
    type: "Branchement",
    dossierType: TypeDossier.EAU,
    libelle: "Branchement",
    template: "Branchement.docx",
  },
  {
    type: "Achevement",
    dossierType: TypeDossier.EAU,
    libelle: "Achèvement",
    template: "ACHEVEMENT.docx",
  },
  {
    type: "ManquePiecesDossierEau",
    dossierType: TypeDossier.EAU,
    libelle: "Manque pièces dossier EAU",
    template: "MANQUE PIECES EAU.docx",
  },
  {
    type: "ModificationBranchementEau",
    dossierType: TypeDossier.EAU,
    libelle: "Modification branchement",
    template: "Modification branchement EAU.docx",
  },
  {
    type: "InstallationCompteurSupplementaire",
    dossierType: TypeDossier.EAU,
    libelle: "Installation compteur supplémentaire",
    template: "INSTALLATION COMPTEUR SUPPLEMENTAIRE.docx",
  },
  // ASS
  {
    type: "AcceptationTravauxAss",
    dossierType: TypeDossier.ASS,
    libelle: "Acceptation de travaux",
    template: "Acceptation Travaux ASS.docx",
  },
  {
    type: "Branchement",
    dossierType: TypeDossier.ASS,
    libelle: "Branchement",
    template: "Branchement.docx",
  },
  {
    type: "ManquePiecesDossierAss",
    dossierType: TypeDossier.ASS,
    libelle: "Manque pièces dossier ASS",
    template: "MANQUE PIECES ASS.docx",
  },
  // CIPA
  {
    type: "AutorisationRaccordement",
    dossierType: TypeDossier.CIPA,
    libelle: "Autorisation de raccordement",
    template: "auto racc.docx",
  },
  {
    type: "AutorisationDeversement",
    dossierType: TypeDossier.CIPA,
    libelle: "Autorisation de déversement",
    template: "ARRETE DEV.docx",
  },
  {
    type: "AccuseReceptionDossierCipaComplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Accusé de réception dossier complet",
    template: "AC RECEP.docx",
  },
  {
    type: "AccuseReceptionDossierCipaIncomplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Accusé de réception dossier incomplet",
    template: "ACC RECEP INCOMPLET.docx",
  },
];

function filterCourrierMetadataByType(type: TypeDossier): Array<CourrierMetadata> {
  return AllCourriersDossierDefinitions.filter((d) => d.dossierType === type);
}

/**
 * Dictionnaire de clé/valeur où:
 * - **clé**: nom d'un template de courrier
 * - **valeur**: libellé associé au template de courrier
 */
export const AllCourriersDossierDefinitionsMap = AllCourriersDossierDefinitions.reduce<
  Record<string, string>
>((acc, def) => {
  acc[def.template] = def.libelle;
  return acc;
}, {});

export const CourriersDossierEauDefinitions = filterCourrierMetadataByType(TypeDossier.EAU);
export const CourriersDossierCipaDefinitions = filterCourrierMetadataByType(TypeDossier.CIPA);
export const CourriersDossierAssDefinitions = filterCourrierMetadataByType(TypeDossier.ASS);
