import { useMemo } from "react";
import type { ReactElement } from "react";
import { Checkbox, Stack } from "@mui/material";
import type { File } from "models";
import { useMail } from "../providers/MailProvider";
import FileItem from "./FileItem";

const HIGHLIGHT_COLOR = "#e0f7f8";

interface FileListItemProps {
  file: File;
}

function SelectableFileItem({ file }: Readonly<FileListItemProps>): ReactElement {
  const { selectFile, unselectFile, selectedFiles } = useMail();

  const isSelected = useMemo(
    () => selectedFiles.some((f) => f.id === file.id),
    [file.id, selectedFiles]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => {
        if (isSelected) {
          unselectFile(file);
        } else {
          selectFile(file);
        }
      }}
      sx={{
        border: isSelected ? `2px solid ${HIGHLIGHT_COLOR}` : "2px solid transparent",
        ":hover": { cursor: "pointer", border: `2px solid ${HIGHLIGHT_COLOR}` },
        background: isSelected ? HIGHLIGHT_COLOR : "initial",
        borderRadius: 2,
        width: "100%",
      }}>
      <Checkbox size="small" checked={isSelected} sx={{ p: 0.7 }} />

      <FileItem file={file} />
    </Stack>
  );
}

export default SelectableFileItem;
