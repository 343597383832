import type { Perimetre } from "models";
import { useDossier } from "providers";
import { useMemo } from "react";
import { isDossierAss, isDossierCipa, isDossierEau, isDossierPac } from "utils/dossierUtil";

function nullableBooleanToString(value: boolean | null | undefined): string | undefined {
  if (value === true) {
    return "Oui";
  } else if (value === false) {
    return "Non";
  }
  return undefined;
}

function perimetreToLibelle(perimetre?: Perimetre): string | undefined {
  const libelleElements: Array<string> = [];
  if (perimetre?.code != null && perimetre.code.trim() !== "") {
    libelleElements.push(perimetre.code);
  }
  if (perimetre?.libelle != null && perimetre.libelle.trim() !== "") {
    libelleElements.push(perimetre.libelle);
  }

  return libelleElements.length > 0 ? libelleElements.join(" - ") : undefined;
}

type Informations = {
  referenceDossier?: string;
  idAvis?: string;
  typeIntervention?: string;
  permissionVoirie?: string;
  libelleFournisseur?: string;
  perimetre?: { id: string; libelle?: string };
  technicien: { id: string; displayName?: string };
  statutDemande?: string;
};

interface IUseDossierInformationsEssentiellesResult {
  hasInformations: boolean;
  hasComplementInformations?: boolean;
  dossierColor?: string;
  informations?: Informations;
}
export function useDossierInformationsEssentielles(): IUseDossierInformationsEssentiellesResult {
  const { dossier } = useDossier();
  const informations = useMemo((): IUseDossierInformationsEssentiellesResult => {
    if (dossier != null) {
      if (isDossierEau(dossier) || isDossierAss(dossier)) {
        const {
          typeIntervention,
          permissionVoirie,
          libelleFournisseur,
          idAvis,
          technicien,
          statutDemande,
          perimetre,
          reference,
          type,
        } = dossier;
        return {
          hasInformations: true,
          hasComplementInformations: true,
          dossierColor: `${type.toLowerCase()}.main`,
          informations: {
            referenceDossier: reference,
            typeIntervention,
            permissionVoirie: nullableBooleanToString(permissionVoirie),
            libelleFournisseur,
            idAvis,
            perimetre:
              perimetre == null
                ? undefined
                : { id: perimetre?.id, libelle: perimetreToLibelle(perimetre) },
            technicien,
            statutDemande,
          },
        };
      }
      if (isDossierCipa(dossier) || isDossierPac(dossier)) {
        const { idAvis, technicien, statutDemande, perimetre, reference, type } = dossier;
        return {
          hasInformations: true,
          hasComplementInformations: false,
          dossierColor: `${type.toLowerCase()}.main`,
          informations: {
            referenceDossier: reference,
            typeIntervention: undefined,
            permissionVoirie: undefined,
            libelleFournisseur: undefined,
            idAvis,
            perimetre:
              perimetre == null
                ? undefined
                : { id: perimetre?.id, libelle: perimetreToLibelle(perimetre) },
            technicien,
            statutDemande,
          },
        };
      }
    }
    return {
      hasInformations: false,
    };
  }, [dossier]);

  return informations;
}
