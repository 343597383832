import { createSvgIcon } from "@mui/material";

export const OpenedFolderIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    fill="currentColor">
    <path d="M 4 3 C 2.904 3 2 3.904 2 5 L 2 18 C 2 18.002 2.0019531 18.005813 2.0019531 18.007812 L 4.3125 10.132812 C 4.5915 9.1808125 5.4787031 8.515625 6.4707031 8.515625 L 21.880859 8.515625 L 22 7 C 22 5.904 21.096 5 20 5 L 10.552734 5 C 10.487734 5 10.425953 4.9747344 10.376953 4.9277344 L 9.0800781 3.6484375 L 9.078125 3.6484375 C 8.657125 3.2334375 8.0880469 3 7.4980469 3 L 4 3 z M 6.4726562 10.015625 C 6.1396562 10.015625 5.8459531 10.234688 5.7519531 10.554688 L 3.2597656 19.046875 C 3.1937656 19.273875 3.2379062 19.518031 3.3789062 19.707031 C 3.5209062 19.896031 3.7415156 20.007813 3.9785156 20.007812 L 19.914062 20.007812 C 20.760063 20.007812 21.486922 19.431344 21.669922 18.652344 L 23.970703 10.980469 C 24.036703 10.753469 23.992563 10.509359 23.851562 10.318359 C 23.709563 10.128359 23.488 10.015625 23.25 10.015625 L 6.4726562 10.015625 z" />
  </svg>,
  "OpenedFolderIcon"
);
