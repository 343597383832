import { type ReactNode } from "react";
import { SdeappsError, withPageErrorBoundary } from "utils/errorHandling";
import Grid from "@mui/material/Grid2";
import FileList from "./FileList";
import { grey } from "@mui/material/colors";
import { MailProvider } from "../providers/MailProvider";
import { LoadingScreen } from "components/Loading";
import { useChantier } from "providers";
import SelectedFilesList from "./SelectedFilesList";

function Mails(): ReactNode {
  const { chantier, isLoading } = useChantier();

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (chantier == null) {
    throw new SdeappsError("LE CHANTIER NE PEUT PAS ETRE NUL !!!!");
  }

  return (
    <MailProvider>
      <Grid container spacing={2} sx={{ p: 2, background: grey[200] }}>
        <Grid container spacing={2}>
          <Grid container size={{ xs: 12, md: 7 }}>
            <Grid
              container
              sx={{
                borderRadius: 1,
                backgroundColor: "white",
              }}
              alignItems="flex-start"
              size={12}>
              <FileList folderName={chantier.folderName} />
            </Grid>

            <Grid
              container
              sx={{
                borderRadius: 1,
                backgroundColor: "white",
              }}
              alignItems="flex-start"
              size={12}>
              <FileList folderName="DOCUMENTS COMMUNS" />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              borderRadius: 1,
              backgroundColor: "white",
              md: { height: "100%" },
            }}
            size={{ xs: 12, md: 5 }}
            alignItems="flex-start">
            <SelectedFilesList />
          </Grid>
        </Grid>
      </Grid>
    </MailProvider>
  );
}
export const MailsWithErrorBoundary = withPageErrorBoundary(Mails);
