import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { FormControl, Autocomplete, TextField, Box } from "@mui/material";
import type { Adresse, ChantierCreateData } from "models";
import { ComplementsDAdresse, AdressePrincipale } from "components/Adresse";
import { adressesService } from "services";

function concateAndTrim(strs: Array<string | undefined>): string {
  return strs.filter(Boolean).join(" ").trim();
}

function generateAdresseLibelle(adresse: Adresse): string {
  const lines: Array<string> = [];
  lines.push(concateAndTrim([adresse.adresseVoie]));
  lines.push(concateAndTrim([adresse.codePostal ?? adresse.codeInsee, adresse.libelleCommune]));
  return lines.filter(Boolean).join(", ");
}

/**
 * Constuire un set de valeurs non nulles et non vides uniques.
 * @returns Un set de valeurs uniques en CAPITALES.
 */
function uniqueUpperCaseSet(arr: Array<string | null | undefined>): Set<string> {
  return new Set(
    arr.flatMap((value) => (value != null && value !== "" ? value.toUpperCase() : []))
  );
}

type AdresseRecherche = Adresse & {
  /** Concaténation des valeurs affichées pour une adresse sélectionnée */
  _libelle: string;
  /** Concaténation des valeurs utilisées pour le filtrage en CAPITALES */
  _RECHERCHE: string;
};

function AdressePicker(): ReactNode {
  const { setValue, watch } = useFormContext<ChantierCreateData>();
  const [adresses, setAdresses] = useState<Array<AdresseRecherche>>([]);

  const [
    avisEauCodeInsee,
    avisEauNomCommune,
    avisAssCodeInsee,
    avisAssNomCommune,
    avisCipaCodeInsee,
    avisCipaNomCommune,
  ] = watch([
    "avis.avisEau.codeInseeCommune",
    "avis.avisEau.nomCommune",
    "avis.avisAss.codeInseeCommune",
    "avis.avisAss.nomCommune",
    "avis.avisCipa.codeInseeCommune",
    "avis.avisCipa.nomCommune",
  ]);
  useEffect(() => {
    async function getAdresses(nomCommune?: string, codeInsee?: string): Promise<void> {
      const adressesExistantes = (await adressesService.getAllAdresses(nomCommune, codeInsee)).map(
        (adresse): AdresseRecherche => {
          const { id, ...values } = adresse;
          return {
            ...adresse,
            _libelle: generateAdresseLibelle(adresse),
            _RECHERCHE: concateAndTrim([...Object.values(values)]).toUpperCase(),
          };
        }
      );

      setAdresses(adressesExistantes);
    }

    const codesInsee = [avisEauCodeInsee, avisAssCodeInsee, avisCipaCodeInsee];
    const uniqueCodesInsee = uniqueUpperCaseSet(codesInsee);
    const nomsCommune = [avisEauNomCommune, avisAssNomCommune, avisCipaNomCommune];
    const uniqueNomsCommune = uniqueUpperCaseSet(nomsCommune);

    if (uniqueCodesInsee.size === 1 && uniqueCodesInsee.size === uniqueNomsCommune.size) {
      const [codeInseeCommune] = uniqueCodesInsee;
      const [nomCommune] = uniqueNomsCommune;
      void getAdresses(nomCommune, codeInseeCommune);
    } else {
      setAdresses([]);
    }
  }, [
    avisEauCodeInsee,
    avisEauNomCommune,
    avisAssCodeInsee,
    avisAssNomCommune,
    avisCipaCodeInsee,
    avisCipaNomCommune,
  ]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        renderInput={(params) => (
          <TextField {...params} label="Adresse existante" placeholder="Adresse existante" />
        )}
        onChange={(_, adresse) => {
          if (adresse != null) {
            // En destructurant le type local, on supprime les propriétés non pertinentes afin de
            // ne pas propager ces ajouts qui n'ont pas d'utilité ni de sens en dehors du composant local.
            const { _libelle, _RECHERCHE, id, ...adresseSelectionnee } = adresse;
            setValue("adresseId", id);
            setValue("adresse", adresseSelectionnee);
          } else {
            setValue("adresseId", undefined);
            setValue("adresse", {});
          }
        }}
        options={adresses}
        renderOption={(props, option: Adresse) => (
          <Box
            component="li"
            {...props}
            key={option.id}
            sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
            <Box>
              <AdressePrincipale adresse={option} />
              <ComplementsDAdresse adresse={option} />
            </Box>
          </Box>
        )}
        getOptionLabel={(option: AdresseRecherche) => option._libelle}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        filterOptions={(options, { inputValue }) => {
          return options.filter((opt) => opt._RECHERCHE.includes(inputValue.toUpperCase()));
        }}
      />
    </FormControl>
  );
}

export default AdressePicker;
