import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import type { Adresse } from "models";

interface AdressePrincipaleProps {
  adresse: Adresse;
  displayCommuneAssocieeDeleguee?: boolean;
}

export function AdressePrincipale({
  adresse,
  displayCommuneAssocieeDeleguee = false,
}: Readonly<AdressePrincipaleProps>): ReactNode {
  return (
    <>
      <Typography>{adresse?.adresseVoie}</Typography>
      <Typography>
        {adresse?.codePostal} {adresse.libelleCommune}
      </Typography>
      {displayCommuneAssocieeDeleguee &&
        adresse?.libelleCommuneAssocieeDeleguee != null &&
        adresse?.libelleCommuneAssocieeDeleguee !== "" && (
          <Typography variant="caption" sx={{ display: "block" }}>
            {"Commune associée déléguée : "}
            {adresse.libelleCommuneAssocieeDeleguee}
          </Typography>
        )}
    </>
  );
}
