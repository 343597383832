import { useForm, useWatch } from "react-hook-form";
import type { Control, UseFormSetValue, SubmitHandler, UseFormReturn } from "react-hook-form";
import { useErrorHandler } from "utils/errorHandling/useErrorHandler";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { TypeDossier } from "models";
import type { ChantierCreateData, ChantierCreateDTO, DossierCreateDTO } from "models";
import { useCallback, useEffect, useMemo } from "react";
import { adressesService, chantierService } from "services";

interface IUseChantierCreationProps {
  /** Fonction appelée lorsque le chantier a été créé */
  onCreationSucceed?: (idChantier: string) => void;
}
/**
 * Permet la gestion de la création d'un chantier avec ses avis, et adresse.
 */
function useChantierCreation({
  onCreationSucceed,
}: IUseChantierCreationProps = {}): IUseChantierCreationResult {
  const { catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    SdeappsErrorBadRequest: (error) => {
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const formMethods = useForm<ChantierCreateData>();
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { isValid },
  } = formMethods;

  // #region RevalidateOnChange
  /**
   * Permet de re-valider les valeurs du formulaire à chaque changement de valeur:
   * Provoque quelques re-renders supplémentaires (probablement à ne pas utiliser
   * sur de gros formulaires) mais épargne beaucoup de prises de têtes pour le formulaire
   * de création de chantiers en particuler.
   */
  const formValues = useWatch({ control });
  useEffect(() => {
    void trigger();
  }, [trigger, formValues]);
  // #endregion

  const sendData: SubmitHandler<ChantierCreateData> = useCallback(
    async function (chantierData: ChantierCreateData): Promise<void> {
      const { adresse, adresseId, avis, perimetres } = chantierData;
      const isAdresseExistante = adresseId != null && adresseId !== "";

      if (!isAdresseExistante) {
        const shouldCreateAdresse =
          Object.values(adresse).filter((value) => value != null).length > 0;
        if (!shouldCreateAdresse) {
          enqueueSnackbar({
            variant: "error",
            message: "Au moins un champ de l'adresse doit être saisi.",
          });
          return;
        }
      }

      if (!isValid) {
        console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
        return;
      }

      const dossiers: Array<DossierCreateDTO> = [];
      if (avis.avisEau != null && avis.avisEau.idAvis !== "")
        dossiers.push({
          type: TypeDossier.EAU,
          idAvis: avis.avisEau.idAvis,
          perimetre: perimetres.perimetreEau,
        });
      if (avis.avisAss != null && avis.avisAss.idAvis !== "")
        dossiers.push({
          type: TypeDossier.ASS,
          idAvis: avis.avisAss.idAvis,
          perimetre: perimetres.perimetreAss,
        });
      if (avis.avisCipa != null && avis.avisCipa.idAvis !== "")
        dossiers.push({
          type: TypeDossier.CIPA,
          idAvis: avis.avisCipa.idAvis,
          perimetre: perimetres.perimetreAss,
        });

      if (dossiers.length === 0) {
        enqueueSnackbar({
          variant: "error",
          message: "Au moins un avis doit être saisi.",
        });
        return;
      }

      await catchErrors(async () => {
        // Création de l'adresse si nouvelle adresse avec au moins un champ renseigné
        const idAdresse = isAdresseExistante
          ? adresseId
          : await adressesService.postAdresse(adresse);
        if (idAdresse == null || idAdresse === "") {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_CREATION_ADRESSE,
          });
          return;
        }

        const chantier: ChantierCreateDTO = { idAdresse, dossiers };
        const idChantier = await chantierService.postChantier(chantier);
        enqueueSnackbar({
          variant: "success",
          message: ToastMessages.CREATED_CHANTIER,
        });
        if (onCreationSucceed != null) {
          onCreationSucceed(idChantier);
        }
      });
    },
    [isValid, catchErrors, onCreationSucceed]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit(sendData, () => {
        enqueueSnackbar({
          variant: "error",
          message: ToastMessages.ERROR_FORM_VALIDATION,
        });
      }),
    [handleSubmit, sendData]
  );

  return {
    formMethods,
    control,
    setValue,
    onSubmit,
    isSending: isLoading,
  };
}

interface IUseChantierCreationResult {
  formMethods: UseFormReturn<ChantierCreateData>;
  /** Control pouvant être assigné aux éléments de type input */
  control: Control<ChantierCreateData, unknown>;
  /** Permet de positionner une valeur des données gérées par le `control` */
  setValue: UseFormSetValue<ChantierCreateData>;
  /** Fonction de création du chantier qui appelle l'API au _submit_ du formulaire */
  onSubmit: (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => Promise<void>;
  isSending: boolean;
}

export default useChantierCreation;
