import { createSvgIcon } from "@mui/material";

export const FolderIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    fill="currentColor">
    <path d="M20.5,5h-9.874c-0.133,0-0.258-0.052-0.352-0.146c0,0,0,0-0.001,0L8.846,3.437C8.563,3.155,8.188,3,7.789,3H3.5	C2.673,3,2,3.673,2,4.5v14C2,19.327,2.673,20,3.5,20h17c0.827,0,1.5-0.673,1.5-1.5v-12C22,5.673,21.327,5,20.5,5z" />
  </svg>,
  "FolderIcon"
);
