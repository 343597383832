export enum Endpoints {
  COMMUNES = "communes",
  PERIMETRES = "perimetres",
  ADRESSES = "adresses",
  CHANTIERS = "chantiers",
  DOSSIER = "dossiers",
  DOSSIER_PAC = "dossier-pac",
  DOCUMENTS_GENERATION = "documents",
  AVIS = "avis",
  CALCUL_ASPECT_FINANCIER_EAU_POTABLE = "calculer-aspect-financier-eau-potable",
  CALCUL_ASPECT_FINANCIER_ASSAINISSEMENT = "calculer-aspect-financier-assainissement",
  CALCUL_ASPECT_FINANCIER_PAC = "calculer-aspect-financier-pac",
  CONTROLES = "controles",
}
