import type { ReactNode } from "react";
import { StatutDossierEau, StatutDossierAss, StatutDossierCipa, StatutDossierPac } from "models";
import type { StatutDossier } from "models";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BorderColorIcon from "@mui/icons-material/BorderColor";

function getStatutDossierColor(statut: StatutDossier): string {
  switch (statut) {
    case StatutDossierEau.CLOS:
    case StatutDossierAss.CLOS:
    case StatutDossierCipa.COMPLET:
    case StatutDossierCipa.RELANCE_CLOTURE:
    case StatutDossierPac.CLOS:
      return "success";

    case StatutDossierEau.AUTRE:
    case StatutDossierEau.ANNULE:
    case StatutDossierEau.ERREUR_DEMANDE:
    case StatutDossierAss.AUTRE:
    case StatutDossierAss.ANNULE:
    case StatutDossierAss.ERREUR_DEMANDE:
    case StatutDossierCipa.INCOMPLET:
    case StatutDossierCipa.PROBLEME_TECHNIQUE:
    case StatutDossierCipa.ANNULE:
      return "error";

    case StatutDossierEau.ATTENTE_REALISATION_TRAVAUX:
    case StatutDossierEau.ATTENTE_DOCUMENTS:
    case StatutDossierEau.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR:
    case StatutDossierAss.ATTENTE_REALISATION_TRAVAUX:
    case StatutDossierAss.ATTENTE_DOCUMENTS:
    case StatutDossierAss.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR:
      return "warning";

    case StatutDossierEau.EN_COURS_TRAITEMENT:
    case StatutDossierPac.EN_COURS_TRAITEMENT:
    case StatutDossierAss.EN_COURS_TRAITEMENT:
    default:
      return "info";
  }
}

function getStatutDossierIcon(
  statut: StatutDossier,
  fontSize: "small" | "inherit" | "large" | "medium" = "small"
): ReactNode {
  switch (statut) {
    case StatutDossierEau.CLOS:
    case StatutDossierAss.CLOS:
    case StatutDossierCipa.COMPLET:
    case StatutDossierCipa.RELANCE_CLOTURE:
    case StatutDossierPac.CLOS:
      return <CheckIcon fontSize={fontSize} />;

    case StatutDossierEau.AUTRE:
    case StatutDossierAss.AUTRE:
      return <QuestionMarkIcon fontSize={fontSize} />;
    case StatutDossierEau.ERREUR_DEMANDE:
    case StatutDossierAss.ERREUR_DEMANDE:
    case StatutDossierCipa.PROBLEME_TECHNIQUE:
      return <ErrorIcon fontSize={fontSize} />;

    case StatutDossierEau.ANNULE:
    case StatutDossierAss.ANNULE:
    case StatutDossierCipa.ANNULE:
      return <ErrorIcon fontSize={fontSize} />;

    case StatutDossierEau.EN_COURS_TRAITEMENT:
    case StatutDossierPac.EN_COURS_TRAITEMENT:
    case StatutDossierAss.EN_COURS_TRAITEMENT:
    case StatutDossierCipa.INCOMPLET:
      return <BorderColorIcon fontSize={fontSize} />;

    case StatutDossierEau.ATTENTE_REALISATION_TRAVAUX:
    case StatutDossierEau.ATTENTE_DOCUMENTS:
    case StatutDossierEau.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR:
    case StatutDossierAss.ATTENTE_REALISATION_TRAVAUX:
    case StatutDossierAss.ATTENTE_DOCUMENTS:
    case StatutDossierAss.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR:
      return <HourglassBottomIcon fontSize={fontSize} />;

    default:
      return null;
  }
}

export { getStatutDossierColor, getStatutDossierIcon };
