import { LinearProgress, MenuItem, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledTextField, ControlledNumberField } from "components/Inputs";
import type { DossierEauTechniqueModifyDTO } from "models";
import { useEffect } from "react";
import type { ReactNode } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { calculService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import financeUtils from "utils/financeUtils";

const fraisMoOptions = [
  { label: "0,00%", value: 0 },
  { label: "6,92%", value: 0.0692 },
];
const fraisDeDossierOptions = [
  { label: "0,00%", value: 0 },
  { label: "3,00%", value: 0.03 },
];
const tvaOptions = [
  { label: "0,00%", value: 0.0 },
  { label: "10,00%", value: 0.1 },
  { label: "20,00%", value: 0.2 },
];
const acompteOptions = [
  { label: "0,00%", value: 0.0 },
  { label: "50,00%", value: 0.5 },
  { label: "80,00%", value: 0.8 },
  { label: "100,00%", value: 1.0 },
];

function AspectFinancierEauForm(): ReactNode {
  const { setValue } = useFormContext<DossierEauTechniqueModifyDTO>();
  const { aspectFinancier } = useWatch<DossierEauTechniqueModifyDTO>();

  const { catchErrors: catchErrorsCalcul, isLoading: isLoadingCalcul } = useSnackbarErrorHandler();

  const debouncedCalcul = useDebouncedCallback(() => {
    void catchErrorsCalcul(async () => {
      if (aspectFinancier != null) {
        const {
          version,
          montantFraisMo,
          montantFraisDeDossier,
          totalHt,
          montantTva,
          totalTtc,
          montantAcompteHt,
          montantAcompteTtc,
          montantTotalTtc,
          ...param
        } = aspectFinancier;

        if (param != null) {
          const res = await calculService.postCalculAspectFinancierEauPotable(param);
          setValue("aspectFinancier.montantFraisMo", res.montantFraisMo);
          setValue("aspectFinancier.montantFraisDeDossier", res.montantFraisDeDossier);
          setValue("aspectFinancier.totalHt", res.totalHt);
          setValue("aspectFinancier.totalTtc", res.totalTtc);
          setValue("aspectFinancier.montantTva", res.montantTva);
          setValue("aspectFinancier.montantAcompteHt", res.montantAcompteHt);
          setValue("aspectFinancier.montantAcompteTtc", res.montantAcompteTtc);
          setValue("aspectFinancier.montantTotalTtc", res.montantTotalTtc);
        }
      }
    });
  }, 500);

  useEffect(debouncedCalcul, [
    debouncedCalcul,
    aspectFinancier?.montantDevis,
    aspectFinancier?.fraisMo,
    aspectFinancier?.fraisDeDossier,
    aspectFinancier?.acompte,
    aspectFinancier?.nombreAcces,
    aspectFinancier?.fraisAcces,
    aspectFinancier?.tva,
  ]);

  return (
    <>
      <Grid sx={{ height: "4px" }} size={12}>
        {isLoadingCalcul && <LinearProgress />}
      </Grid>
      <Grid size={12}>
        <ControlledNumberField
          label="Montant devis HT"
          name="aspectFinancier.montantDevis"
          type="decimal"
          withEndEuro
        />
      </Grid>
      <Grid size={4}>
        <ControlledTextField
          name="aspectFinancier.fraisMo"
          select
          defaultValue={0}
          label="Frais M.O.">
          {fraisMoOptions.map((v) => (
            <MenuItem key={v.label} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={8}>
        <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.montantFraisMo)}</Typography>
      </Grid>
      <Grid size={4}>
        <ControlledTextField
          name="aspectFinancier.fraisDeDossier"
          select
          defaultValue={0}
          label="Frais de dossier">
          {fraisDeDossierOptions.map((v) => (
            <MenuItem key={v.label} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={8}>
        <Typography>
          {financeUtils.toRoundedEuroString(aspectFinancier?.montantFraisDeDossier)}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Divider></Divider>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-start" size={6}>
        <Typography>Total H.T.</Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={6}>
        <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.totalHt)}</Typography>
      </Grid>
      <Grid size={4}>
        <ControlledTextField name="aspectFinancier.tva" select label="T.V.A." defaultValue={0.2}>
          {tvaOptions.map((v) => (
            <MenuItem key={v.label} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={8}>
        <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.montantTva)}</Typography>
      </Grid>
      <Grid size={12}>
        <Divider></Divider>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-start" size={6}>
        <Typography>Total T.T.C.</Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={6}>
        <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.totalTtc)}</Typography>
      </Grid>
      <Grid size={4}>
        <ControlledTextField
          name="aspectFinancier.acompte"
          select
          defaultValue={0.5}
          label="Acompte">
          {acompteOptions.map((v) => (
            <MenuItem key={v.label} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={8}>
        <Typography>
          {financeUtils.toRoundedEuroString(aspectFinancier?.montantAcompteHt)}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Divider></Divider>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-start" size={6}>
        <Typography>Acompte T.T.C.</Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={6}>
        <Typography>
          {financeUtils.toRoundedEuroString(aspectFinancier?.montantAcompteTtc)}
        </Typography>
      </Grid>
      <Grid size={12}>
        <ControlledNumberField
          label="Nombre de frais d'accès"
          name="aspectFinancier.nombreAcces"
          type="entier"
        />
      </Grid>
      <Grid size={12}>
        <ControlledNumberField
          label="Frais accès T.T.C."
          name="aspectFinancier.fraisAcces"
          type="decimal"
          withEndEuro
        />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid container alignItems="center" size={6}>
        <Typography>Montant Total T.T.C.</Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" size={6}>
        <Typography>
          {financeUtils.toRoundedEuroString(aspectFinancier?.montantTotalTtc)}
        </Typography>
      </Grid>
      <Grid size={12}>
        <ControlledNumberField
          label="Montant facturé T.T.C."
          name="aspectFinancier.montantFactureTtc"
          type="decimal"
          withEndEuro
        />
      </Grid>
    </>
  );
}

export default AspectFinancierEauForm;
