import ConnectionPage from "pages/ConnectionPage";
import { routesConfig } from "config/app-config";
import { createBrowserRouter } from "react-router-dom";
import { BaseErrorPage } from "utils/errorHandling";

export const unAuthenticatedRouter = createBrowserRouter([
  {
    errorElement: <BaseErrorPage />,
    children: [
      {
        path: routesConfig.any.path,
        element: <ConnectionPage />,
      },
    ],
  },
]);
