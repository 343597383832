import type { ReactNode } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import type { SelectChangeEvent } from "@mui/material";
import { useController } from "react-hook-form";
import type { PathValue, FieldPath, FieldValues } from "react-hook-form";
import type { ControlledInput } from "utils/rules";

interface ControlledObjectSelectProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  disabled?: boolean;
  readOnly?: boolean;
  children?: ReactNode;
  label: string;
  defaultHelperText?: string;
  menuItems: Array<PathValue<T, Name>> | undefined;
  menuItemKeyName: string;
  menuItemLibelleName: string;
}

export function ControlledObjectSelect<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  rules,
  defaultValue,
  menuItems,
  label,
  disabled,
  menuItemKeyName,
  menuItemLibelleName,
  readOnly,
  defaultHelperText,
}: Readonly<ControlledObjectSelectProps<T, Name>>): ReactNode {
  const {
    field: { value, ref, onChange },
    fieldState: { error },
  } = useController({
    name,
    rules,
    defaultValue,
  });

  function handleChange(event: SelectChangeEvent<HTMLInputElement>): void {
    const res = menuItems?.find((x) => Reflect.get(x, menuItemKeyName) === event.target.value);
    if (res != null) onChange(res);
  }
  const labelId = `${label}-label-id`;

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={labelId} error={error != null}>
        {label}
      </InputLabel>
      <Select
        id={`${label}-id`}
        labelId={labelId}
        value={value != null ? Reflect.get(value, menuItemKeyName) : ""}
        label={label}
        error={error != null}
        onChange={handleChange}
        inputRef={ref}
        name={name}
        inputProps={{ readOnly }}
        defaultValue={defaultValue}>
        {menuItems?.map((p) => (
          <MenuItem key={Reflect.get(p, menuItemKeyName)} value={Reflect.get(p, menuItemKeyName)}>
            {Reflect.get(p, menuItemLibelleName)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error != null}>{error?.message ?? defaultHelperText}</FormHelperText>
    </FormControl>
  );
}
