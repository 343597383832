import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useRequiredParams } from "hooks";
import type { Adresse, AdresseModifyDTO } from "models";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { adressesService } from "services";
import { LoadingScreen } from "components/Loading";
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageLayout, FormSection } from "components/Layout";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ControlledTextField } from "components/Inputs";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { compare } from "fast-json-patch";
import type { Operation } from "fast-json-patch";
import LoadingButton from "components/LoadingButton";

const gridSpacing = 2;

function ModifieAdressePage(): ReactNode {
  const { idAdresse } = useRequiredParams<{ idAdresse: string }>();
  const navigate = useNavigate();
  const [adresse, setAdresse] = useState<Adresse>();
  const { catchErrors, isLoading } = useErrorHandler();
  const { catchErrors: catchSendingErrors, isLoading: isSending } = useErrorHandler(false);

  const formMethods = useForm<AdresseModifyDTO>();
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    async function getAdresse(): Promise<void> {
      const _adresse = await adressesService.getAdresse(idAdresse);
      setAdresse(_adresse);
      const { codeInsee, libelleCommune, ...modifyAdresse } = _adresse;
      reset(modifyAdresse);
    }

    void catchErrors(getAdresse);
  }, [catchErrors, idAdresse, reset]);

  async function sendData(modifiedAdresse: AdresseModifyDTO): Promise<void> {
    await catchSendingErrors(async () => {
      const { codeInsee, libelleCommune, ...initialAdresse } = adresse ?? {};
      const patchData: Array<Operation> = compare(initialAdresse, modifiedAdresse);
      await adressesService.patch(idAdresse, patchData);
      navigate(-1);
    });
  }

  if (isLoading || adresse == null) {
    return (
      <PageLayout pageTitle="Modifier une adresse">
        <LoadingScreen />
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle="Modifier une adresse">
      <FormProvider {...formMethods}>
        <Grid
          container
          spacing={gridSpacing}
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(sendData, () => {
            enqueueSnackbar({
              variant: "error",
              message: ToastMessages.ERROR_FORM_VALIDATION,
            });
          })}>
          <FormSection title="Adresse">
            <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
              <Grid container spacing={gridSpacing} size={{ xs: 12, md: "grow" }}>
                <Grid size={12}>
                  <ControlledTextField
                    name="adresseVoie"
                    label="Adresse"
                    placeholder="3, Rue de la gare"
                  />
                </Grid>
                <Grid size={12}>
                  <ControlledTextField
                    name="codePostal"
                    label="Code Postal *"
                    placeholder="67000"
                    rules={{ required: "Le Code Postal est obligatoire" }}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField disabled value={adresse.libelleCommune} label="Commmune" fullWidth />
                </Grid>
                {adresse.libelleCommuneAssocieeDeleguee != null &&
                  adresse.libelleCommuneAssocieeDeleguee !== "" && (
                    <Grid size={12}>
                      <TextField
                        disabled
                        value={adresse.libelleCommuneAssocieeDeleguee}
                        label="Commmune Associee Deleguee"
                        fullWidth
                      />
                    </Grid>
                  )}
              </Grid>

              <Grid container spacing={gridSpacing} size={{ xs: 12, md: "grow" }}>
                <Grid size={12}>
                  <ControlledTextField name="lotissement" label="Lotissement" />
                </Grid>
                <Grid size={12}>
                  <ControlledTextField name="routeDepartementale" label="Route départementale" />
                </Grid>
                <Grid size={12}>
                  <ControlledTextField name="parcelle" label="Parcelle" />
                </Grid>
                <Grid size={12}>
                  <ControlledTextField name="lot" label="Lot" />
                </Grid>
                <Grid size={12}>
                  <ControlledTextField name="section" label="Section" />
                </Grid>
              </Grid>
            </Grid>
          </FormSection>

          <FormSection>
            <Grid container spacing={2} justifyContent="flex-end" size={12}>
              <Grid size="auto">
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    navigate(-1);
                  }}>
                  Annuler
                </Button>
              </Grid>
              <Grid size="auto">
                <LoadingButton type="submit" startIcon={<EditIcon />} loading={isSending}>
                  Modifier l'adresse
                </LoadingButton>
              </Grid>
            </Grid>
          </FormSection>
        </Grid>
      </FormProvider>
    </PageLayout>
  );
}
const ModifieAdressePageWithErrorBoundary = withPageErrorBoundary(ModifieAdressePage);

export default ModifieAdressePageWithErrorBoundary;
