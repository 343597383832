import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { FormControlLabel, FormHelperText, Switch } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledTextField, CommuneAutoCompleteWithErrorBoundary } from "components/Inputs";
import { useController, useFormContext, useWatch } from "react-hook-form";
import type { AdresseCreateDTO, ChantierCreateData, Commune } from "models";
import AdressePicker from "./AdressePicker";
import { communesService } from "services";
import { useErrorHandler } from "utils/errorHandling";

interface AdresseFormProps {
  gridSpacing?: number;
}

function AdresseForm({ gridSpacing = 2 }: Readonly<AdresseFormProps>): ReactNode {
  const [isNouvelleAdresse, setIsNouvelleAdresse] = useState<boolean>(false);
  const [displayCommuneDelegueeInput, setDisplayCommuneDelegueeInput] = useState<boolean>(false);
  const [communesAssocieesDeleguees, setCommunesAssocieesDeleguees] = useState<Array<Commune>>([]);
  const [communes, setCommunes] = useState<Array<Commune>>([]);

  const { control, setValue, trigger, resetField } = useFormContext<ChantierCreateData>();
  const {
    isLoading: isCadLoading,
    catchErrors: catchCadErrors,
    error: errorCad,
  } = useErrorHandler({
    defaultIsLoading: false,
  });
  const {
    isLoading: isCommunesLoading,
    catchErrors: catchCommunesErrors,
    error: errorCommunes,
  } = useErrorHandler();

  const isCadValid = (value: AdresseCreateDTO): boolean =>
    displayCommuneDelegueeInput ? value.codeInseeCommuneAssocieeDeleguee != null : true;

  const adresse = useWatch({
    control,
    name: "adresse",
  });

  const {
    fieldState: { error },
  } = useController({
    control,
    name: "adresse",
    rules: {
      validate: (_value) =>
        !isNouvelleAdresse ||
        (_value != null &&
          Object.entries(_value).filter(
            ([key, value]) =>
              value != null &&
              value !== "" &&
              [
                "libelleCommuneAssocieeDeleguee",
                "codeInsee",
                "libelleCommune",
                "codeInseeCommuneAssocieeDeleguee",
              ].find((k) => k === key) == null
          ).length > 1 &&
          _value?.codeInsee != null &&
          _value?.codePostal != null &&
          isCadValid(_value)) ||
        "Les champs suivants sont obligatoires : la commune, commune associée-déléguée (si présente), le code postal et au moins un autre champ.",
    },
  });

  const {
    fieldState: { error: errorId },
  } = useController({
    control,
    name: "adresseId",
    rules: {
      validate: (_value) =>
        isNouvelleAdresse ||
        (_value != null && _value !== "") ||
        "L'adresse est obligatoire; veuillez sélectionner une adresse existante ou en saisir une nouvelle.",
    },
  });

  useEffect(() => {
    void trigger();
  }, [trigger]);

  useEffect(() => {
    async function getCommuneAssocieesDeleguees(codeInsee: string): Promise<void> {
      const availableCads = await communesService.getCommunesAssocieesDelegueesByCommune(codeInsee);
      setCommunesAssocieesDeleguees(availableCads);
    }

    resetField("adresse.codeInseeCommuneAssocieeDeleguee");
    resetField("adresse.libelleCommuneAssocieeDeleguee");

    setCommunesAssocieesDeleguees([]);
    if (adresse?.codeInsee != null) {
      const codeInsee = adresse.codeInsee;
      void catchCadErrors(async () => {
        await getCommuneAssocieesDeleguees(codeInsee);
      });
    } else {
      setDisplayCommuneDelegueeInput(false);
    }
  }, [adresse?.codeInsee, catchCadErrors, resetField]);

  useEffect(() => {
    if (communesAssocieesDeleguees.length !== 0) {
      setDisplayCommuneDelegueeInput(true);
    } else {
      setDisplayCommuneDelegueeInput(false);
    }
  }, [communesAssocieesDeleguees]);

  useEffect(() => {
    void catchCommunesErrors(async (): Promise<void> => {
      setCommunes(await communesService.getAll());
    });
  }, [catchCommunesErrors]);

  return (
    <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
      <Grid size={12}>
        <FormControlLabel
          control={
            <Switch
              checked={isNouvelleAdresse}
              onChange={(_, checked) => {
                setIsNouvelleAdresse(checked);
                setValue("adresseId", undefined);
                setValue("adresse", {});
              }}
            />
          }
          label="Saisir l'adresse vous-même"
        />
      </Grid>
      {!isNouvelleAdresse ? (
        <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
          <Grid size={12}>
            <AdressePicker />
          </Grid>
          {errorId != null && (
            <Grid size={12}>
              <FormHelperText error>{errorId.message}</FormHelperText>
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.adresseVoie"
                label="Adresse"
                placeholder="3, Rue de la gare"
              />
            </Grid>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.codePostal"
                label="Code Postal"
                placeholder="67000"
              />
            </Grid>
            <Grid size={12}>
              <CommuneAutoCompleteWithErrorBoundary
                communes={communes}
                selectedCommuneId={adresse.codeInsee}
                isLoading={isCommunesLoading}
                onChange={(commune) => {
                  setValue("adresse.codeInsee", commune?.id);
                  setValue("adresse.libelleCommune", commune?.libelle);
                }}
                error={errorCad}
              />
            </Grid>

            {(communesAssocieesDeleguees.length !== 0 || isCadLoading) && (
              <Grid size={12}>
                <CommuneAutoCompleteWithErrorBoundary
                  communes={communesAssocieesDeleguees}
                  selectedCommuneId={adresse.codeInseeCommuneAssocieeDeleguee}
                  isLoading={isCadLoading}
                  onChange={(cda) => {
                    setValue("adresse.codeInseeCommuneAssocieeDeleguee", cda?.id);
                    setValue("adresse.libelleCommuneAssocieeDeleguee", cda?.libelle);
                  }}
                  label="Commune Associée / Déléguée"
                  error={errorCommunes}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
            <Grid size={12}>
              <ControlledTextField name="adresse.lotissement" label="Lotissement" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.routeDepartementale"
                label="Route départementale"
              />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.parcelle" label="Parcelle" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.lot" label="Lot" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.section" label="Section" />
            </Grid>
          </Grid>
          {error != null && (
            <Grid size={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default AdresseForm;
