import type {
  DossierAss,
  DossierCipa,
  DossierEau,
  DossierPac,
  StatutDossierAss,
  StatutDossierCipa,
  StatutDossierEau,
  StatutDossierPac,
} from ".";
import type { Perimetre } from "models";

export enum TypeDossier {
  EAU = "EAU",
  ASS = "ASS",
  CIPA = "CIPA",
  PAC = "PAC",
}

export interface DossierCreateDTO {
  idAvis?: string;
  type: TypeDossier;
  perimetre?: Perimetre;
}

export interface DossierBase extends DossierCreateDTO {
  id: string;
  idChantier: string;
  technicien: { id: string; displayName?: string };
  reference?: string;
  // chemin dans Sharepoint, depuis l'intérieur du répertoire du Chantier vers le répertoire du Dossier
  // ex: "EAU 123 CUL"
  folderName: string;
}

export type Dossier = DossierEau | DossierAss | DossierCipa | DossierPac;

export type StatutDossier =
  | StatutDossierEau
  | StatutDossierAss
  | StatutDossierCipa
  | StatutDossierPac;

export enum AllStatutDossier {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  INCOMPLET = "Incomplet",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  ANNULE = "Annulé",
  ERREUR_DEMANDE = "Erreur demande",
  AUTRE = "Autres (voir observations)",
  PROBLEME_TECHNIQUE = "Problème technique",
  COMPLET = "Complet",
  RELANCE_CLOTURE = "Relancé clôturé",
  CLOS = "Clos",
  CLOS_EXECUTES = "Clos (travaux exécutés)",
}
